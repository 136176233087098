<template>
  <!--begin::Post-->
  <div class="post d-flex flex-column-fluid" id="kt_post">
    <!--begin::Container-->
    <div id="kt_content_container" class="container">
      <!--begin::Basic info-->
      <div class="card mb-5 mb-xl-10">
        <!--begin::Card header-->
        <div
          class="card-header border-0 cursor-pointer"
          role="button"
          data-bs-toggle="collapse"
          data-bs-target="#kt_account_profile_details"
          aria-expanded="true"
          aria-controls="kt_account_profile_details"
        >
          <!--begin::Card title-->
          <div class="card-title m-0">
            <h3 class="fw-bolder m-0">เพิ่มสิทธิ์การเข้าถึง</h3>
          </div>
          <!--end::Card title-->
        </div>
        <!--begin::Card header-->

        <!--begin::Content-->
        <div id="kt_account_profile_details" class="collapse show">
          <!--begin::Form-->
          <Form
            v-if="permission"
            @submit="onSubmitPermission"
            :validation-schema="permissionValidator"
            id="kt_account_profile_details_form"
            class="form"
            novalidate="novalidate"
          >
            <!--begin::Card body-->
            <div class="card-body border-top p-9">
              <!--begin::Input group-->
              <div class="row mb-6">
                <!--begin::Label-->
                <label class="col-lg-4 col-form-label required fw-bold fs-6"
                  >พารามิเตอร์</label
                >
                <!--end::Label-->

                <!--begin::Col-->
                <div class="col-lg-8 fv-row">
                  <Field type="hidden" name="id" v-model="permission.id" />
                  <Field
                    type="text"
                    name="name"
                    class="form-control form-control-lg form-control-solid"
                    placeholder="พารามิเตอร์"
                    v-model="permission.name"
                  />
                  <div class="fv-plugins-message-container">
                    <div class="fv-help-block">
                      <ErrorMessage name="name" />
                    </div>
                  </div>
                </div>
                <!--end::Col-->
              </div>
              <!--end::Input group-->

              <!--begin::Input group-->
              <div class="row mb-6">
                <!--begin::Label-->
                <label class="col-lg-4 col-form-label required fw-bold fs-6"
                  >ชื่อ</label
                >
                <!--end::Label-->

                <!--begin::Col-->
                <div class="col-lg-8 fv-row">
                  <Field
                    type="text"
                    name="title"
                    class="form-control form-control-lg form-control-solid"
                    placeholder="ชื่อ"
                    v-model="permission.title"
                  />
                  <div class="fv-plugins-message-container">
                    <div class="fv-help-block">
                      <ErrorMessage name="title" />
                    </div>
                  </div>
                </div>
                <!--end::Col-->
              </div>
              <!--end::Input group-->

              <!--begin::Input group-->
              <div class="row mb-6">
                <!--begin::Label-->
                <label class="col-lg-4 col-form-label required fw-bold fs-6"
                  >ประเภท</label
                >
                <!--end::Label-->

                <!--begin::Col-->
                <div class="col-lg-8 fv-row">
                  <Field
                    type="text"
                    name="mode"
                    class="form-control form-control-lg form-control-solid"
                    placeholder="ประเภท"
                    v-model="permission.mode"
                  />
                  <div class="fv-plugins-message-container">
                    <div class="fv-help-block">
                      <ErrorMessage name="mode" />
                    </div>
                  </div>
                </div>
                <!--end::Col-->
              </div>
              <!--end::Input group-->
            </div>
            <!--end::Card body-->

            <!--begin::Actions-->
            <div class="card-footer d-flex justify-content-end py-6 px-9">
              <button
                type="submit"
                id="kt_account_profile_details_submit"
                ref="submitButton"
                class="btn btn-primary"
              >
                <span class="indicator-label"> ยืนยัน </span>
                <span class="indicator-progress">
                  กรุณารอสักครู่ ...
                  <span
                    class="spinner-border spinner-border-sm align-middle ms-2"
                  ></span>
                </span>
              </button>
            </div>
            <!--end::Actions-->
          </Form>
          <!--end::Form-->
        </div>
        <!--end::Content-->
      </div>
      <!--end::Basic info-->
    </div>
  </div>
</template>

<script>
import { defineComponent, onMounted, ref } from "vue";
import { ErrorMessage, Field, Form } from "vee-validate";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import { useRoute } from "vue-router";
import usePermissions from "@/core/services/api/permissions";
import * as Yup from "yup";
import useSweetalert from "@/core/helpers/sweetalert2";
import { checkPage } from "@/core/helpers/checkpage";

export default defineComponent({
  name: "permission-create",
  components: {
    ErrorMessage,
    Field,
    Form,
  },
  setup() {
    const { getPermission, permission, updatePermission } = usePermissions();
    const { Sconfirm, SToast } = useSweetalert();
    const submitButton = ref(null);
    const route = useRoute();
    const permissionValidator = Yup.object().shape({
      name: Yup.string().required("กรุณากรอก พารามิเตอร์").label("พารามิเตอร์"),
      title: Yup.string().required("กรุณากรอก ชื่อ").label("ชื่อ"),
      mode: Yup.string().required("กรุณากรอก ประเภท").label("ประเภท"),
    });

    const onSubmitPermission = (values) => {
      Sconfirm("ยืนยัน การแก้ไขสิทธิ์การเข้าถึง", "question").then(
        async (result) => {
          if (result.isConfirmed) {
            if (submitButton.value) {
              // Activate indicator
              submitButton.value.setAttribute("data-kt-indicator", "on");
            }
            await updatePermission(values)
              .then(() => {
                SToast("success", "แก้ไข สิทธิ์การเข้าถึง สำเร็จ");
                submitButton.value?.removeAttribute("data-kt-indicator");
              })
              .catch((error) => {
                submitButton.value?.removeAttribute("data-kt-indicator");
                if (error.response) {
                  let errors = error.response.data.errors;

                  if (errors.name) {
                    SToast("error", `${errors.name[0]}`);
                  }
                  if (errors.title) {
                    SToast("error", `${errors.title[0]}`);
                  }
                  if (errors.name && errors.title) {
                    SToast(
                      "error",
                      `${errors.name[0]} ,<br> ${errors.title[0]}`
                    );
                  }
                }
              });
          }
        }
      );
    };

    onMounted(() => {
      getPermission(route.params.id);
      checkPage("permission-edit");
      setCurrentPageBreadcrumbs("แก้ไขสิทธิ์การเข้าถึง", ["สิทธิ์การเข้าถึง"]);
    });

    return {
      permission,
      permissionValidator,
      submitButton,
      onSubmitPermission,
    };
  },
});
</script>

<style></style>
